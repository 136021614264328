import { LANGUAGES } from './LANGUAGES'
import countryToFlag from './helpers/countryToFlag'

const REGION_FLAGS = LANGUAGES.map((language) => {
  return {
    market: language.code,
    flagComponent: countryToFlag(language.code),
  }
})

const getCountryFlag = (isoCode: string) => {
  const flag = REGION_FLAGS.find((item) => item.market === isoCode)
  if (flag?.flagComponent) return flag.flagComponent
  return isoCode
}

export { REGION_FLAGS, getCountryFlag }
