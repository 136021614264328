import { Merchant, MerchantPaymentMethods } from '@/types/merchant'

export const getWorldFirstDataFromMerchant = (
  merchant: Merchant | undefined
) => {
  if (!merchant) {
    return null
  }

  return {
    isWorldFirst:
      merchant?.allowed_payment_methods?.includes('WF') ||
      merchant?.is_chinese_merchant,
    connectedUSD: merchant?.payment_methods?.find(
      ({ name, value }: MerchantPaymentMethods) =>
        name === 'WF-USD' && value === 'enabled'
    ),
    connectedEUR: merchant?.payment_methods?.find(
      ({ name, value }: MerchantPaymentMethods) =>
        name === 'WF-EUR' && value === 'enabled'
    ),
  }
}

export const validCurrency = ['USD', 'EUR']

export const callbackResultMsg = [
  {
    message: 'SUCCESS',
    status: 'S',
    description: 'Success',
    action: 'None, you are done.',
  },
  {
    message: 'PARAM_ILLEGAL',
    status: 'F',
    description: 'Illegal parameter',
    action: 'Retry with the correct parameters.',
  },
  {
    message: 'CURRENCY_ALREADY_EXISTS',
    status: 'F',
    description: 'Currency already connected',
    action: 'None, you are already connected with this currency.',
  },
  {
    message: 'CURRENCY_OUT_OF_SUPPORTED_RANGE',
    status: 'F',
    description: 'Currency is not supported',
    action: 'Retry with the correct currency.',
  },
  {
    message: 'SYSTEM_BUSY',
    status: 'U',
    description: 'System is busy',
    action: 'Retry later or contact WorldFirst Technical Support.',
  },
  {
    message: 'SIGNATURE_VERIFICATION_FAILED',
    status: 'F',
    description: 'Invalid signature',
    action: 'Retry later or contact Fyndiq Technical Support.',
  },
]
