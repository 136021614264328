import fetch from 'node-fetch'
import { newRequestId } from '../../helpers/requestId'
import config, { getConfig } from '../../../config'
import { logService } from '../log'

const { baseUrl } = config

const apiUrl =
  process.env.NODE_ENV === 'production' && !process.browser
    ? 'http://merchantcenter'
    : baseUrl

const setCookie = (name, value, hours) => {
  const encodedValue = encodeURIComponent(value)
  const data =
    hours === null
      ? `${name}=${encodedValue}; path=/;`
      : `${name}=${encodedValue}; path=/; max-age=${60 * 60 * hours};`
  document.cookie = data
}

const loginUserService = ({ merchantId, merchantToken }) => {
  const relativeURL = `api/auth`
  const options = {
    headers: {
      merchantId,
      merchantToken,
      'x-request-id': newRequestId(),
      'x-referer-baseurl': config.baseUrl,
      'x-referer-pathname': getConfig().pathname || window.location.pathname,
      'x-referer-env': config.isProduction ? 'prod' : 'dev',
    },
    method: 'GET',
  }

  const url = `${apiUrl}/${relativeURL}`
  return fetch(url, options).then((res) => {
    if (res.status > 200) {
      logService({
        error: {},
        context: 'service.auth.login',
        data: {
          status: res.status,
          merchantId,
        },
      })
      return { error: 'Could not login' }
    }
    return res.json()
  })
}

const LOCALSTORAGE_MERCHANTINFO_KEY = 'merchantInfo'

const setMerchantInfo = (data) => {
  if (window?.localStorage) {
    delete data.token // do NOT store the token/password to localStorage
    delete data.tags
    window.localStorage.setItem(
      LOCALSTORAGE_MERCHANTINFO_KEY,
      JSON.stringify(data)
    )
  }
}

const getMerchantInfo = () => {
  try {
    if (window?.localStorage) {
      const data = window.localStorage.getItem(LOCALSTORAGE_MERCHANTINFO_KEY)
      try {
        if (JSON.parse(data)) {
          return JSON.parse(data)
        }
      } catch (e) {
        console.error('No merchant info available.')
        return null
      }
    }
  } catch (e) {
    console.error(e)
  }
}

const destroyMerchantInfo = () => {
  if (window?.localStorage) {
    try {
      window.localStorage.removeItem(LOCALSTORAGE_MERCHANTINFO_KEY)
      return true
    } catch (e) {
      return null
    }
  }
}

const destroyLoginCookies = () => {
  destroyMerchantInfo()
  document.cookie = 'merchantId=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT'
  document.cookie =
    'merchantToken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT'
  document.cookie =
    'merchantName=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT'
  document.cookie =
    'merchantLegalName=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT'
  document.cookie =
    'merchantVatNumber=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT'
  document.cookie =
    'merchantOrganizationNumber=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT'
}

const loginUser = (data) => {
  const {
    legal_name,
    id,
    token,
    name: merchantName,
    vat_number,
    organization_number,
  } = data

  setCookie('merchantId', id, 8)
  setCookie('merchantToken', token, 8)
  setCookie('merchantName', merchantName)
  setCookie('merchantVatNumber', vat_number)
  setCookie('merchantOrganizationNumber', organization_number)
  setCookie('merchantLegalName', legal_name)

  setMerchantInfo(data)
}

export {
  loginUserService,
  loginUser,
  getMerchantInfo,
  destroyLoginCookies,
  setMerchantInfo,
  setCookie,
  destroyMerchantInfo,
}
