import React from 'react'
import App from 'next/app'
import Script from 'next/script'
import Head from 'next/head'
import nextCookie from 'next-cookies'
import { createGlobalStyle } from 'styled-components'
import * as Sentry from '@sentry/node'
import { appWithTranslation } from 'next-i18next'
import config, { patchConfig } from '../../config'
import Layout from '../components/layout'
import { FeaturesProvider } from '../components/features/FeaturesProvider'
import { loginUserService } from '@/services/auth'
import { getCategoriesService } from '../services/categories'
import gtmInitScript from '../externalScripts/gtmInitScript'
import { googleTagManagerTrackingId } from '../tracking'

const { buildHash, buildId, isProduction } = config
if (typeof window !== 'undefined') {
  window.info = {
    buildId,
    buildHash,
  }
}

if (isProduction && config.sentryDSN && config.sentryDSN.length > 11) {
  Sentry.init({
    dsn: config.sentryDSN,
  })
}

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: Mulish, Arial, sans-serif;
    background-color: rgba(0, 113, 119, 0.01);
  }
  .report-style-class {
    height: 100%;
  }
  button.MuiIconButton-root {
    padding : 12px !important;
  }    
`

const PrintStyle = createGlobalStyle`
  @media print {
    header {
      display: none !important;
    }

    #picking-list-print-button {
      display: none !important;
    }
  }
`

class MyApp extends App {
  render() {
    const { Component, pageProps } = this.props
    const isLoggedIn = !!pageProps.user.merchantId
    const getLayout = Component.getLayout || ((page) => page)
    return (
      <>
        <Head>
          <link rel="shortcut icon" href="/favicon.ico" />
        </Head>
        <Script
          id="googleTagManagerTrackingScript"
          async
          dangerouslySetInnerHTML={{
            __html: gtmInitScript(googleTagManagerTrackingId),
          }}
        />
        <GlobalStyle />
        <PrintStyle />
        <FeaturesProvider features={pageProps.features}>
          <Layout
            isLoggedIn={isLoggedIn}
            user={pageProps.user}
            storefront={pageProps.storefront}
          >
            {getLayout(<Component {...pageProps} />)}
          </Layout>
        </FeaturesProvider>
      </>
    )
  }
}

MyApp.getInitialProps = async ({ Component, ctx }) => {
  patchConfig({
    pathname: ctx.req ? ctx.req.originalUrl : ctx.asPath,
  })

  const fetchPromises = []
  if (Component.getInitialProps) {
    fetchPromises.push(Component.getInitialProps(ctx))
  }
  const fetchedData = await Promise.all(fetchPromises)
  const pageProps = fetchedData.pop() || {}
  const appWideNamespaces = ['common', 'errors']
  const storefront = config.storefront || 'Fyndiq'
  const { merchantId, merchantToken, merchantName, ...allCookies } =
    nextCookie(ctx)
  if (ctx.asPath !== '/500') {
    // Get categories to the cache ASAP on server run
    getCategoriesService({
      market: 'SE',
      language: 'sv',
    })
    getCategoriesService({
      market: 'SE',
      language: 'en',
    })
  }

  const merchant =
    merchantId && merchantToken
      ? await loginUserService({ merchantId, merchantToken })
      : null

  return {
    pageProps: {
      features: Object.keys(config.featureFlags).reduce((acc, key) => {
        return {
          ...acc,
          [key]: allCookies[key] || `${config.featureFlags[key]}`,
        }
      }, {}),
      user: {
        merchantId,
        merchantToken,
        merchantName,
        complete: merchant?.data,
      },
      ...pageProps,
      namespacesRequired: [
        ...(pageProps.namespacesRequired || []),
        ...appWideNamespaces,
      ],
      storefront,
    },
  }
}

export default appWithTranslation(MyApp)
