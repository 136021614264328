import { addToDataLayer } from './dataLayer'

const login = () => {
  addToDataLayer({
    event: 'login',
    loginMethod: 'loginForm',
  })
}

export default { login }
