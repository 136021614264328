import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import {
  Home,
  BusinessCenter,
  LocalShipping,
  Assessment,
  AccountBalanceWallet,
  AccountTree,
  AttachMoney,
  AccountBalance,
  HelpOutline,
} from '@material-ui/icons'
import { AppBar, Toolbar } from '@material-ui/core'
import { Text } from '@fyndiq/design-system'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import LanguageSelector from '../general/LanguageSelector'
import FyndiqLogo from './FyndiqLogo'
import FyndiqLogoWithoutText from './FyndiqLogoWithoutText'
import NavigationLink from './NavigationLink'
import UserMenu from '../authentication/UserMenu'
import { getWorldFirstDataFromMerchant } from '@/helpers/worldfirst'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 0;
`

const NavigationContainer = styled.div`
  flex-grow: 1;
`

const StyledToolBar = styled(Toolbar)`
  display: flex;
  flex-direction: column;
`

const StyledText = styled(Text)`
  && {
    font-weight: 700;
    padding-bottom: 10px;
    font-size: 18px;
    line-height: 21.6px;
  }
  @media screen and (min-width: 767px) {
    && {
      font-size: 32px;
      line-height: 38.4px;
    }
  }
`

const signupStartPagePath = '/signup'
const signupFinishPagePath = '/signup/finish'

const Header = ({ isLoggedIn, user, storefront }) => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const [navLinks, setNavLink] = useState([])

  const setLanguage = (newLang) => {
    document.cookie = `next-i18next=${newLang}; path=/`
    router.push(router.pathname, router.asPath, {
      locale: newLang,
    })
  }

  const baseNavLinks = [
    {
      label: t('Home'),
      href: '/',
      icon: <Home />,
      dataCy: 'home-nav',
      order: 1,
    },
    {
      label: t('Articles'),
      href: '/articles',
      icon: <BusinessCenter />,
      dataCy: 'articles-nav',
      order: 10,
    },
    {
      label: t('Orders'),
      href: '/orders',
      icon: <LocalShipping />,
      dataCy: 'orders-nav',
      order: 20,
    },
    {
      label: t('Analytics'),
      href: '/analytics',
      icon: <Assessment />,
      dataCy: 'analytics-nav',
      order: 30,
    },
    {
      label: t('Economy'),
      href: '/economy',
      icon: <AccountBalanceWallet />,
      dataCy: 'economy-nav',
      order: 40,
    },
    {
      label: t('categories'),
      href: '/categories',
      icon: <AccountTree />,
      dataCy: 'categories-nav',
      order: 50,
    },
    // Temporary hide calculator until it is fixed
    // https://fyndiq.atlassian.net/browse/MIT-74
    // {
    //   label: t('calculator.heading'),
    //   href: '/calculator',
    //   icon: <AttachMoney />,
    //   dataCy: 'calculator-nav',
    //   order: 60,
    // },
    {
      label: t('Help'),
      href:
        storefront === 'Fyndiq'
          ? t('Help center link Fyndiq')
          : t('Help center link CDON'),
      icon: <HelpOutline />,
      dataCy: 'help-center-nav',
      target: '_blank',
      order: 70,
    },
  ]

  useEffect(() => {
    if (isLoggedIn) {
      const worldfirst = getWorldFirstDataFromMerchant(user.complete)
      if (worldfirst?.isWorldFirst) {
        baseNavLinks.push({
          label: t('Worldfirst'),
          href: '/economy/worldfirst',
          icon: <AccountBalance />,
          dataCy: 'worldfirst-nav',
          order: 41,
        })
      }
      setNavLink(baseNavLinks.sort((a, b) => a.order - b.order))
    } else {
      setNavLink(baseNavLinks.slice(0, 1))
    }
  }, [isLoggedIn])

  const getHeaderContent = () => {
    if (
      router.pathname === signupStartPagePath ||
      router.pathname === signupFinishPagePath
    ) {
      return null
    }
    if (router.pathname.startsWith('/signup/step')) {
      return (
        <StyledToolBar>
          <Container>
            <FyndiqLogoWithoutText />
          </Container>
          <StyledText>{t('Merchant application form')}</StyledText>
        </StyledToolBar>
      )
    }
    if (isLoggedIn) {
      return (
        <Toolbar>
          <Container>
            <FyndiqLogo />
          </Container>
          <NavigationContainer>
            {navLinks &&
              navLinks.map((navLink) => (
                <NavigationLink
                  href={navLink.href}
                  label={navLink.label}
                  icon={navLink.icon}
                  active={navLink.href === router.pathname}
                  key={navLink.label}
                  dataCy={navLink.dataCy}
                  target={navLink.target}
                />
              ))}
          </NavigationContainer>
          <div>
            <LanguageSelector
              language={router.locale}
              setLanguage={setLanguage}
            />
            <UserMenu user={user} />
          </div>
        </Toolbar>
      )
    }
    return (
      <Toolbar>
        <Container>
          <FyndiqLogo />
        </Container>
      </Toolbar>
    )
  }

  return (
    <AppBar position="static" color="primary">
      {getHeaderContent()}
    </AppBar>
  )
}

Header.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object,
}

Header.defaultProps = {
  user: null,
}

export default Header
