import getNextJSConfig from 'next/config'

const { publicRuntimeConfig = {} } = getNextJSConfig() || {}

let pathedConfig = {}

export const patchConfig = (patch) => {
  pathedConfig = { ...pathedConfig, ...patch }
}
export const getConfig = () => ({ ...publicRuntimeConfig, ...pathedConfig })

export default { ...publicRuntimeConfig, ...pathedConfig }
