import React from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IdleTimer from 'react-idle-timer'
import Router from 'next/router'
import Header from './Header'
import ThemeProvider from './ThemeProvider'
import { destroyLoginCookies } from '../../services/auth'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Content = styled.main`
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
`

const Layout = ({ children, isLoggedIn, user, storefront }) => {
  const timeout = 1000 * 60 * 30 // Logout after 30 minutes of inactivity.
  const onIdle = () => {
    destroyLoginCookies()
    Router.push('/')
  }

  return (
    <ThemeProvider>
      <Head>
        <title>Fyndiq - Merchant Center</title>
      </Head>
      <Wrapper>
        <IdleTimer onIdle={onIdle} timeout={timeout}>
          <Header isLoggedIn={isLoggedIn} user={user} storefront={storefront} />
          <Content>{children}</Content>
        </IdleTimer>
      </Wrapper>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
}
export default Layout
