import React from 'react'

const FyndiqLogoWithoutText = ({
  width = '108px',
  height = '39px',
}: {
  width?: string
  height?: string
}) => (
  <svg
    width={`${width}`}
    height={`${height}`}
    viewBox="0 0 108 39"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#F95D7A"
        d="M101.554 3.314c-.492-1.749-2.03-3.27-4.09-3.314H11.17C9.097.048 7.563 1.581 6.998 3.314 4.93 8.624 0 13.874 0 19.711c0 5.837 4.93 11.086 6.998 16.397.564 1.732 2.099 3.263 4.173 3.31h86.292c2.06-.043 3.597-1.563 4.09-3.31 1.789-5.362 5.997-10.64 5.997-16.397 0-5.758-4.208-11.035-5.996-16.397"
      />
      <path
        fill="#FEFEFE"
        d="M63.441 10.01c5.374 0 9.015 3.995 9.015 9.018v.601c-.037 4.988-3.67 8.94-9.04 8.94h-4.644c-.804 0-1.482-.695-1.482-1.52V11.506c0-.824.678-1.495 1.482-1.495h4.67zm25.216.009c4.987.002 9.03 4.149 9.03 9.269.001 2.058-.683 3.946-1.796 5.488l1.047 1.27c.513.624.436 1.558-.172 2.084s-1.516.447-2.03-.176l-4.979-6.05c-.513-.624-.436-1.557.172-2.084.608-.525 1.516-.447 2.03.176l2.006 2.438c.524-.927.841-1.992.841-3.146 0-1.748-.686-3.318-1.8-4.465-1.118-1.143-2.647-1.846-4.35-1.848-1.703.002-3.232.705-4.35 1.848-1.116 1.147-1.8 2.717-1.802 4.465.001 1.747.686 3.315 1.802 4.463 1.118 1.144 2.647 1.848 4.35 1.848.397 0 .782-.04 1.154-.11.782-.155 1.537.371 1.687 1.173.148.8-.363 1.575-1.144 1.73-.55.107-1.118.163-1.696.163-4.99 0-9.033-4.148-9.034-9.267.001-5.12 4.044-9.267 9.034-9.27zm-67.027.006c.752 0 1.356.619 1.356 1.392 0 .8-.604 1.416-1.356 1.416h-6.9v4.794h6.9c.752 0 1.356.617 1.356 1.415 0 .774-.604 1.391-1.356 1.391h-6.9v6.596c0 .825-.676 1.521-1.48 1.521-.803 0-1.482-.696-1.482-1.52V11.52c0-.824.68-1.495 1.482-1.495h8.38zm54.426.027c.803 0 1.48.669 1.48 1.518v15.46c0 .824-.677 1.52-1.48 1.52-.804 0-1.482-.696-1.482-1.52V11.57c0-.85.678-1.518 1.482-1.518zm-22.332 0c.803 0 1.48.669 1.48 1.518v15.5c.002.498-.194.964-.575 1.238-.246.177-.541.253-.844.24l-.061.002c-.433 0-.83-.203-1.104-.519-.097-.09-.186-.193-.265-.307l-7.899-11.315.001 10.62c0 .825-.677 1.521-1.48 1.521-.803 0-1.482-.696-1.482-1.52V11.538c.005-.489.207-.94.584-1.21.14-.101.296-.17.46-.21.139-.043.286-.067.438-.067.51 0 .97.27 1.236.684.048.055.094.114.138.176l7.89 11.304V11.57c0-.85.68-1.518 1.483-1.518zm-15.044 0c.778 0 1.357.643 1.357 1.416 0 .36-.102.645-.302.9l-6.001 7.344v7.318c0 .824-.678 1.52-1.482 1.52-.803 0-1.48-.696-1.48-1.52v-7.19l-5.976-7.315c-.252-.31-.352-.646-.352-.98 0-.85.654-1.494 1.457-1.494.452 0 .854.18 1.153.54l5.25 6.417 5.297-6.442c.25-.334.677-.515 1.08-.515zm24.761 2.768h-3.189v12.915h3.19c3.638 0 5.94-2.69 5.975-6.106v-.6c0-3.452-2.31-6.21-5.976-6.21z"
      />
    </g>
  </svg>
)

export default FyndiqLogoWithoutText
