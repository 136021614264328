import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Link from 'next/link'
import { Button } from '@material-ui/core'

const LinkLabel = styled.span`
  font-size: 14px;
`

const LinkButton = styled.a`
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 3px solid transparent;
  opacity: 0.8;
  color: #fff;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    text-decoration: none;
    border-bottom-color: #fff;
  }
  &:hover ${LinkLabel} {
    text-decoration: none;
  }

  ${({ active }) =>
    active &&
    `
    opacity: 1;
    border-bottom-color: #fff;
  `}
`

const NavigationLink = ({ href, label, icon, active, dataCy, target }) => (
  <Button color="inherit" data-cy={dataCy}>
    <Link href={href} passHref>
      <LinkButton active={active} target={target}>
        {icon}
        <LinkLabel>{label}</LinkLabel>
      </LinkButton>
    </Link>
  </Button>
)

NavigationLink.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  dataCy: PropTypes.string,
  target: PropTypes.string,
}

NavigationLink.defaultProps = {
  dataCy: '',
  target: undefined,
}

export default NavigationLink
