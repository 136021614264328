import React from 'react'
import styled from 'styled-components'
import { AccountCircle, PowerSettingsNew } from '@material-ui/icons'
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import Router from 'next/router'
import { useTranslation } from 'next-i18next'
import { destroyLoginCookies } from '../../services/auth'
import { User } from '@/shared/types'
import Link from 'next/link'

const InfoRow = styled.div`
  padding: 6px 16px;
`

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  padding: 6px 16px;
`

const StyledAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.fyndiqRed.main};
`

const UserMenu = ({ user }: { user: User }) => {
  const { t } = useTranslation('common')
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const { merchantName, merchantId } = user

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logOut = () => {
    handleClose()
    destroyLoginCookies()
    Router.push('/')
  }

  return (
    <>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <StyledAvatar>
          {merchantName && merchantName.length >= 1 ? (
            merchantName[0]
          ) : (
            <AccountCircle />
          )}
        </StyledAvatar>
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <InfoRow>
          <Typography color="primary" variant="caption">
            {t('Logged in as')}
          </Typography>
          <Typography>{merchantName}</Typography>
        </InfoRow>
        <InfoRow>
          <Typography color="primary" variant="caption">
            ID
          </Typography>
          <pre style={{ margin: 0 }}>{merchantId}</pre>
        </InfoRow>
        <Divider style={{ margin: '8px 0' }} />
        <Link href="/account" passHref>
          <StyledMenuItem onClick={handleClose}>
            <ListItemIcon>
              <AccountCircle fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('Account settings')} />
          </StyledMenuItem>
        </Link>
        <StyledMenuItem onClick={logOut}>
          <ListItemIcon>
            <PowerSettingsNew fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('Log out')} />
        </StyledMenuItem>
      </Menu>
    </>
  )
}

export default UserMenu
