import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles'
import { theme } from '@fyndiq/design-system'

// Custom Fyndiq theme
const customTheme = createTheme({
  palette: {
    primary: {
      main: '#007177',
    },
    secondary: {
      main: '#e9f3f5',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
    },
    font: {
      main: '#003b4d',
      light: '#8a8e93',
    },
    highlight: '#eecc11',
    fyndiqRed: {
      main: '#F95D75',
    },
    ...theme.palette,
  },
  typography: { fontFamily: ['Mulish', 'Arial', 'sans-serif'].join(',') },
})

const ThemeProvider = ({ children }) => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={customTheme}>
      <StyledComponentsThemeProvider theme={customTheme}>
        {children}
      </StyledComponentsThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>
)

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ThemeProvider
