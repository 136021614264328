/*
  value/code/name - 
  required - always add for language based objects, like article title
  nav - translations for nav/site
*/
const LANGUAGES = [
  { value: 'sv', code: 'SE', name: 'Swedish', required: false, nav: true },
  { value: 'da', code: 'DK', name: 'Danish', required: false, nav: false },
  { value: 'fi', code: 'FI', name: 'Finnish', required: false, nav: false },
  { value: 'nb', code: 'NO', name: 'Norwegian', required: false, nav: false },
  { value: 'en', code: 'US', name: 'English', required: true, nav: true },
]

const languageMap = {
  sv: 'sv-SE',
  da: 'da-DK',
  fi: 'fi-FI',
  nb: 'nb-NO',
  en: 'en-US',
}

const inverseLanguageMap = {
  'sv-SE': 'sv',
  'da-DK': 'da',
  'fi-FI': 'fi',
  'nb-NO': 'nb',
  'en-US': 'en',
}

export { LANGUAGES, languageMap, inverseLanguageMap }
