import fetch from 'node-fetch'
import { newRequestId } from '../../helpers/requestId'
import config, { getConfig } from '../../../config'
import { languageMap } from '../../LANGUAGES'

const { baseUrl } = config

const apiUrl =
  process.env.NODE_ENV === 'production' && !process.browser
    ? 'http://merchantcenter'
    : baseUrl

const getCategoriesService = ({ market, language }) => {
  const fomattedLanguage = languageMap[language]
  const relativeURL = `api/categories?market=${market}&language=${fomattedLanguage}`
  const options = {
    headers: {
      'x-request-id': newRequestId(),
      'x-referer-baseurl': config.baseUrl,
      'x-referer-pathname': getConfig().pathname || window.location.pathname,
      'x-referer-env': config.isProduction ? 'prod' : 'dev',
    },
    method: 'GET',
  }

  const url = `${apiUrl}/${relativeURL}`

  return fetch(url, options)
    .then((res) => {
      if (res.status > 200) {
        return { error: 'Could not get categories' }
      }
      return res.json()
    })
    .catch(() => {
      return { error: 'Could not get categories' }
    })
}

export { getCategoriesService }
