import React from 'react'
import PropTypes from 'prop-types'

const FeaturesContext = React.createContext()

const FeaturesProvider = ({ features, children }) => {
  return (
    <FeaturesContext.Provider value={[features]}>
      {children}
    </FeaturesContext.Provider>
  )
}

FeaturesProvider.propTypes = {
  features: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
}

export { FeaturesProvider, FeaturesContext }
