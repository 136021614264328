import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core'
import { LANGUAGES } from '../../LANGUAGES'
import { getCountryFlag } from '../../CountryFlags'

const MenuWrapper = styled(Menu)`
  & .MuiMenu-list {
    display: flex;
    flex-direction: column;

    padding: 0;
  }
`

const MenuButton = styled(MenuItem)`
  padding: 10px 20px;
  justify-content: flex-start;
`

const LanguageSelector = ({
  showLabel,
  language,
  setLanguage,
  overrideShowAll = false,
  doNotShowEnglish,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const displayedLanguages = doNotShowEnglish
    ? LANGUAGES.filter((lang) => lang.value !== 'en')
    : LANGUAGES
  const selectedLang = doNotShowEnglish
    ? displayedLanguages.find((lang) => lang.value === language)
    : LANGUAGES.find((lang) => lang.value === language)

  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onLangClick = (lang) => {
    setLanguage(lang)
    handleClose()
  }

  return (
    <>
      <IconButton
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        data-cy="general-language-selector"
      >
        <span>{getCountryFlag(selectedLang.code)}</span>
        {showLabel && (
          <Typography variant="body2">{selectedLang.name}</Typography>
        )}
      </IconButton>
      <MenuWrapper
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {displayedLanguages.map((langObject) => {
          if (!langObject.nav && !overrideShowAll) {
            return null
          }
          return (
            <MenuButton
              key={langObject.value}
              onClick={() => onLangClick(langObject.value)}
              data-cy={`general-language-selector-${langObject.value}`}
            >
              <span style={{ marginRight: showLabel ? '10px' : '' }}>
                {getCountryFlag(langObject.code)}
              </span>
              {showLabel && langObject.name}
            </MenuButton>
          )
        })}
      </MenuWrapper>
    </>
  )
}

LanguageSelector.propTypes = {
  setLanguage: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  overrideShowAll: PropTypes.bool,
  showLabel: PropTypes.bool,
  doNotShowEnglish: PropTypes.bool,
}

LanguageSelector.defaultProps = {
  showLabel: false,
  overrideShowAll: false,
  doNotShowEnglish: false,
}

export default LanguageSelector
