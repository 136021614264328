import fetch from 'node-fetch'
import { newRequestId } from '../../helpers/requestId'
import config, { getConfig } from '../../../config'

const { baseUrl } = config

const apiUrl =
  process.env.NODE_ENV === 'production' && !process.browser
    ? 'http://merchantcenter'
    : baseUrl

const logService = ({ error, context, data }) => {
  const relativeURL = 'api/log'

  const body = {
    error,
    data: {
      ...data,
      context,
    },
  }

  const options = {
    headers: {
      'x-request-id': newRequestId(),
      'x-referer-baseurl': config.baseUrl,
      'x-referer-pathname': getConfig().pathname || window.location.pathname,
      'x-referer-env': config.isProduction ? 'prod' : 'dev',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(body),
  }

  return fetch(`${apiUrl}/${relativeURL}`, options).then((res) => {
    return res.json()
  })
}

export { logService }
